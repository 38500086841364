// 79909
@import '../helpers/componentHeader';
// 79909
// 47913 Start
// 100030
// 108737
.bannerwith-lefttext{
    background-color: $white-smoke;   
        overflow-x: hidden;
        margin-left: -15px;
        margin-right: -15px;

    .bannerwith-lefttext__wrapper{
        width: 100%;
        position: relative;  
        @include media-breakpoint-up(md) {
            max-width: 1600px;            
            margin: 0 auto;   
        }
        .bannerwith-lefttext__bg{
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 0;
            background-repeat: no-repeat;
            background-position: right center;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        & > .container{
            padding: 0px;
            @media (max-width: 1199px){
                max-width: inherit;
            }
        }
        .bannerwith-lefttext__content{
            display: flex;
            @include media-breakpoint-up(md) {
                min-height: 520px;
                align-items: center;
            }
            @include media-breakpoint-down(sm) {
                flex-flow:column;
            }
            .banner-left{

                @include media-breakpoint-up(md) {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
                position: relative;
                z-index: 1;
                padding: 20px 30px;
                background-image: none!important;
                }

                @include media-breakpoint-up(xl) {
                    padding: 20px 50px 20px 0;
                }

                @include media-breakpoint-down(sm) {
                    background-position: 20%;
                    padding: 50px 25px;
                }
                .text-title{
                    text-align: center;
                    width: auto;
                    margin: 0;
                    font-size: 18px;
                    line-height: 19px;
                    color: $primary;
                    font-weight: normal;
                    letter-spacing: 0;
                    font-family: $secondary-font-medium;
                    @include media-breakpoint-up(md) {
                        text-align: left;
                        font-size: 36px;
                        line-height: 36px;
                    }
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        line-height: 26px;
                    }

                    &:before{
                        display: none;
                    }

                    &:after{
                        content:'';
                        height: 2px;
                        width:64px;
                        background: $yellow;
                        margin-top:10px;
                        margin-bottom: 14px;
                        margin-right: auto;
                        margin-left: auto;
                        display: block;
                        @include media-breakpoint-up(md) {
                            margin-top:16px;
                            margin-bottom: 18px;
                            text-align: left;
                            font-size: 36px;
                            line-height: 46px;
                            margin-left: 0;
                        }
                        @include media-breakpoint-down(sm) {
                            width:36px;
                            margin-top:10px;
                        }

                    }
                }
                .text-description{
                    font-family: $primary-font-semibold;
                    font-size: 16px;
                    line-height: 26px;
                    color: $black;
                    margin-bottom: 10px;

                    @include media-breakpoint-up(md){
                        font-size: 18px;
                        line-height: 32px;
                    }
                }
            }
            .banner-right{

                @include media-breakpoint-up(md) {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 50%;
                    flex: 0 0 50%;
                    max-width: 50%;
                }
                @include media-breakpoint-down(sm) {
                    max-height: 340px;
                    width: 100%;
                    overflow: hidden;
                }
                @include media-breakpoint-down(xs) {
                    max-height:260px;
                }
                .photo-tile-figure{
                    width: 100%;
                    height: 100%;
                    margin: 0px;

                    img{
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }        
    }
    // 107529
    &__infographic-view {
        &-container {
            @include media-breakpoint-up(xl) {
                padding: 0px 90px;
            }
        }
        &-row {
            display: flex;
            justify-content: center;
            flex-flow: column;
            height: 100%;
        }
        .text-title{
            width: auto;
            margin: 0;
            font-size: 18px;
            line-height: 26px;
            color: $primary-darkest;
            font-weight: normal;
            font-family: $secondary-font-medium;
            margin-top: 12px;
            @include media-breakpoint-up(md) {
                font-size: 24px;
            }
            &:after{
                content:'';
                height: 2px;
                width:64px;
                background: $yellow;
                margin-top:10px;
                margin-bottom: 14px;
                display: block;
                @include media-breakpoint-up(md) {
                    margin-top:16px;
                    margin-bottom: 18px;
                    text-align: left;
                    font-size: 36px;
                    line-height: 46px;
                }
                @include media-breakpoint-down(sm) {
                    width:36px;
                    margin-top:10px;
                }
            }
        }
        .text-description{
            font-family: $primary-font-semibold;
            font-size: 16px;
            line-height: 26px;
            color: $black;
            margin: 30px 50px 0px 0px;
            p {
                margin: 0px;
            }
            @include media-breakpoint-up(md) {
                font-size: 18px;
                line-height: 32px;
            }
            @include media-breakpoint-down(sm) {
                margin: 16px 0px 45px 0px;
            }
        }
        .banner-right {
            .photo-tile-figure{
                width: 100%;
                height: 100%;
                margin: 0px;

                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
//47913 End
